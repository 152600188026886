<template>
    <div class="main-section">
        <div class="section">
            <span class="header-space section-header left">Drivers</span>
            <DataTable v-if="this.drivers" :value="this.drivers" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]"
                removableSort stripedRows>
                <template #header>
                    <div style="text-align: left">
                        <Button icon="pi pi-external-link" label="Export" @click="this.exportIntoDriversTemplate($event)" />
                    </div>
                </template>
                <Column field="name" header="Name" sortable />
                <Column field="state" header="State" sortable />
                <Column field="licenseNumber" header="License #" sortable />
                <Column field="birthDate" header="Birth Date" sortable />
                <Column field="hireDate" header="Hire Date" sortable />
                <Column field="yearsExperience" header="Years Experience" sortable />
            </DataTable>
        </div>
    </div>
</template>

<script>
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
    name: 'Drivers',
    props: {
        drivers: Array
    },
    methods: {
        async exportIntoDriversTemplate() {
            try {
                const response = await fetch('/Drivers_Template.xlsx');
                if (!response.ok) {
                    throw new Error(`Error getting Driver Template: ${response.status}`);
                }

                const arrayBuffer = await response.arrayBuffer();
                const workbook = new ExcelJS.Workbook();
                await workbook.xlsx.load(arrayBuffer);
                const worksheet = workbook.getWorksheet(1);
                let startRow = 5;

                this.drivers.forEach((driver) => {
                    const row = worksheet.getRow(startRow);
                    row.getCell(1).value = driver.name;
                    row.getCell(2).value = driver.state;
                    row.getCell(3).value = driver.licenseNumber;
                    row.getCell(4).value = driver.birthDate;
                    row.getCell(6).value = driver.hireDate;
                    row.getCell(7).value = driver.yearsExperience;
                    startRow++;
                });

                const buffer = await workbook.xlsx.writeBuffer();
                saveAs(new Blob([buffer]), 'drivers.xlsx');
            } catch (error) {
                console.error('Error exporting Excel file:', error);
            }
        }
    }
}
</script>