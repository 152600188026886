<template>
    <div class="main-section">
        <div class="section">
            <span class="header-space section-header left">Additional Coverage</span>
            <DataTable v-if="this.additionalCoverage" :value="this.additionalCoverage" paginator :rows="10"
                :rowsPerPageOptions="[5, 10, 20, 50]" removableSort stripedRows>
                <Column field="lineType" header="Line Type" sortable />
                <Column field="description" header="Description" sortable />
                <Column field="deductible" header="Deductible" sortable />
                <Column field="limit" header="Limit" sortable />
            </DataTable>
        </div>
        <div class="section">
            <span class="header-space section-header left">Additional Interests</span>
            <DataTable v-if="this.additionalInsured" :value="this.additionalInsured" paginator :rows="10"
                :rowsPerPageOptions="[5, 10, 20, 50]" removableSort stripedRows>
                <Column field="description" header="Description" sortable />
                <Column field="address" header="Address" sortable />
                <Column field="city" header="City" sortable />
                <Column field="state" header="State" sortable />
                <Column field="zipCode" header="Zip Code" sortable />
                <Column field="additionalInterestDescription" header="Interest" sortable />
                <Column field="additionalInterestOther" header="If 'Other'" sortable />
            </DataTable>
        </div>
    </div>
    <div class="main-section">
        <div class="section">
            <span class="header-space section-header left">Physical Coverage</span>
            <DataTable v-if="this.physicalCoverage" :value="this.physicalCoverage" paginator :rows="10"
                :rowsPerPageOptions="[5, 10, 20, 50]" removableSort stripedRows>
                <Column field="lineType" header="Line Type" sortable />
                <Column field="policyNumber" header="Policy #" sortable />
                <Column field="compDeductible" header="Comp. Deductible" sortable />
                <Column field="collDeductible" header="Coll. Deductible" sortable />
            </DataTable>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdditionalCoverages',
    props: {
        additionalCoverage: Array,
        physicalCoverage: Array,
        additionalInsured: Array
    },
    created() {
        let additionalInterestsCodeMapping = {
            "A1" : "OLT - Architects, Engineers, or Surveyors (use AG instead)",
            "A7" : "Additional Insured - State or Political Sub-divisions - Permits",
            "AD" : "Additional Interest",
            "AI" : "Additional Insured",
            "AL" : "Additional Interest Lessor",
            "AM" : "Additional Insured and Mortgagee",
            "AN" : "Additional Name Insured",
            "AS" : "Additional Insured Lessor",
            "AT" : "Additional Insured Lessor and Loss Payee",
            "CH" : "Certificate Holder",
            "DI" : "Designated Insured",
            "EL" : "Employee as Lessor",
            "IL" : "Additional Insured and Lienholder",
            "LA" : "Loss Payee and Additional Interest",
            "LC" : "Additional Interest -- Loss Payable Conditions",
            "LH" : "Lienholder",
            "LI" : "Loss Payee and Additional Insured",
            "LL" : "Land Lease Interests",
            "LO" : "Lenders Loss Payable",
            "LP" : "Loss Payable",
            "LS" : "Lessor of Leased Equipment",
            "MG" : "Mortgagee",
            "ON" : "OWNER",
            "OT" : "OTHER",
            "PI" : "Loss payee, Additional Insured, and Additional Interest"
        }
        this.additionalInsured.forEach(element => {
            element.additionalInterestDescription = additionalInterestsCodeMapping[element.additionalInterestCode];
        });
    }
}
</script>