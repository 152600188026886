<template>
    <div class="main-section">
        <div class="section">
            <span class="header-space section-header left">Equipment</span>
            <DataTable v-if="this.equipment" :value="this.equipment" paginator :rows="10"
                :rowsPerPageOptions="[5, 10, 20, 50]" removableSort stripedRows>
                <template #header>
                    <div style="text-align: left">
                        <Button icon="pi pi-external-link" label="Export" @click="this.exportIntoEquipmentTemplate($event)" />
                    </div>
                </template>
                <Column field="vehicleNumber" header="Vehicle Number" sortable />
                <Column field="vehicleYear" header="Vehicle Year" sortable />
                <Column field="make" header="Make" sortable />
                <Column field="vin" header="VIN" sortable />
                <Column field="type" header="Type" sortable />
                <Column field="vehicleType" header="Vehicle Type" sortable />
                <Column field="state" header="State" sortable/>
                <Column field="costNew" header="Cost New" sortable/>
                <Column field="LossPayee" header="Loss Payee" sortable/>
                <Column field="LossPayeeAddress" header="Loss Payee Address" sortable/>
            </DataTable>
        </div>
    </div>
</template>

<script>
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
    name: 'Equipment',
    props: {
        equipment: Array
    },
    methods: {
        async exportIntoEquipmentTemplate() {
            try {
                const response = await fetch('/Equipment_Template.xlsx');
                if (!response.ok) {
                    throw new Error(`Error getting Driver Template: ${response.status}`);
                }

                const arrayBuffer = await response.arrayBuffer();
                const workbook = new ExcelJS.Workbook();
                await workbook.xlsx.load(arrayBuffer);
                const worksheet = workbook.getWorksheet(1);
                let startRow = 5;

                this.equipment.forEach((vehicle) => {
                    const row = worksheet.getRow(startRow);
                    row.getCell(1).value = vehicle.vehicleNumber;
                    row.getCell(2).value = vehicle.vehicleYear;
                    row.getCell(3).value = vehicle.make;
                    row.getCell(4).value = vehicle.vin;
                    row.getCell(5).value = vehicle.type;
                    row.getCell(6).value = vehicle.vehicleType;
                    row.getCell(7).value = vehicle.costNew;
                    row.getCell(8).value = vehicle.state;
                    startRow++;
                });

                const buffer = await workbook.xlsx.writeBuffer();
                saveAs(new Blob([buffer]), 'equipment.xlsx');
            } catch (error) {
                console.error('Error exporting Excel file:', error);
            }
        }
    }
}
</script>