<template>
    <Toast />
    <div id="spinner" class="spinner">
        <ProgressSpinner />
    </div>
    <Error :errorMessage="this.errorMessage" />
    <div id="container" class="container center">
        <div v-if="this.epicSnapshot" class="db-header">
            <h1 v-if="this.epicSnapshot?.clientInfo != undefined">EPIC SNAPSHOT: {{
                this.epicSnapshot.clientInfo.clientName }}</h1>
            <div class="label-text-group-center main-section-center">
                <h2 class="account-balance-label">Account Balance: </h2>
                <h2 v-if="this.epicSnapshot?.clientInfo.accountBalance === 0" class="green">${{
                    Math.abs(this.epicSnapshot?.clientInfo.accountBalance) }}</h2>
                <h2 v-else-if="this.epicSnapshot?.clientInfo.accountBalance > 0" class="red">${{
                    Math.abs(this.epicSnapshot?.clientInfo.accountBalance) }} owed to RP</h2>
                <h2 v-else class="green">${{ Math.abs(this.epicSnapshot?.clientInfo.accountBalance) }} credit to RP</h2>
            </div>
            <small v-if="this.mode === 'development'"> {{ this.url }}</small>
        </div>
        <div class="center container-new">
            <div class="column">
                <ClientInfo v-if="this.epicSnapshot?.clientInfo" :client-info="this.epicSnapshot?.clientInfo"
                    :operations-safety="this.epicSnapshot?.operationsSafety" />
            </div>
            <div class="column">
                <Commodities v-if="this.epicSnapshot?.commodities && this.epicSnapshot?.radius"
                    :commodities="this.epicSnapshot?.commodities" :radius="this.epicSnapshot?.radius" />
            </div>
            <div class="column">
                <Safety v-if="this.epicSnapshot?.operationsSafety" :safety="this.epicSnapshot?.operationsSafety" />
                <StickyNotes v-if="this.epicSnapshot?.stickyNotes" :stickyNotes="this.epicSnapshot?.stickyNotes" />
            </div>
        </div>
    </div>
    <div class="container-new">
        <PolicyOverview v-if="this.epicSnapshot?.policyOverview" :policy-overview="this.epicSnapshot?.policyOverview"
            :header="this.policyHeader" @doubleclick="this.doubleclickPolicy" @clearFilter="this.clearFilter" />
    </div>
    <div>
        <AdditionalCoverages v-if="this.additionalCoverageFiltered"
            :additional-coverage="this.additionalCoverageFiltered" :physical-coverage="this.physicalCoverageFiltered"
            :additional-insured="this.additionalInsuredFiltered" />
    </div>
    <div class="container-new">
        <Drivers v-if="this.epicSnapshot?.drivers" :drivers="this.driversFiltered" />
    </div>
    <div class="container-new">
        <Equipment v-if="this.epicSnapshot?.equipment" :equipment="this.equipmentFiltered" />
    </div>
</template>

<script>
import { EpicRepository } from "@/repositories/epicRepository.js";
import Error from "@/components/Error.vue";
import ClientInfo from "@/components/ClientInfo.vue";
import PolicyOverview from "@/components/PolicyOverview.vue";
import Commodities from "@/components/Commodities.vue";
import Safety from "@/components/Safety.vue";
import AdditionalCoverages from "@/components/AdditionalCoverages.vue";
import Drivers from "@/components/Drivers.vue";
import Equipment from "@/components/Equipment.vue";
import StickyNotes from "@/components/StickyNotes.vue";
import Enumerable from 'linq';
import { useToast } from "primevue/usetoast";

export default {
    name: 'Dashboard',
    components: {
        Error,
        ClientInfo,
        PolicyOverview,
        Commodities,
        Safety,
        AdditionalCoverages,
        Drivers,
        Equipment,
        StickyNotes
    },
    data() {
        return {
            toast: useToast(),
            epicSnapshot: undefined,
            errorMessage: '',
            additionalCoverageFiltered: undefined,
            physicalCoverageFiltered: undefined,
            additionalInsuredFiltered: undefined,
            driversFiltered: undefined,
            equipmentFiltered: undefined,
            policyHeader: 'Policy Overview',
            mode: process.env.VUE_APP_MODE,
            url: process.env.VUE_APP_API_URL,
        }
    },
    created() {
        this.$emitter.on("login", () => {
            this.showContainer();
        }),
            this.$emitter.on("logout", () => {
                this.hideContainer();
            })
    },
    async mounted() {
        this.hideSpinner();
        this.hideContainer();

        let windowLocationSearch = window.location.search;
        let splitString = windowLocationSearch.split('?lookupcode=');
        let lookupCode = splitString[1];

        if (!lookupCode) {
            this.errorMessage = "Invalid or empty Epic lookup code.";
        }
        else {
            this.hideError();

            this.$emitter.on("login", async (account) => {
                this.account = account;
                await this.getData(lookupCode); //populate the data after login is completed

                if (this.epicSnapshot?.clientInfo?.lookupCode !== undefined) {
                    this.showContainer();
                }
                else {
                    this.hideContainer();
                    this.hideSpinner();
                    this.errorMessage = 'No data found for this client.';
                    this.showError();
                }
            }),
                this.$emitter.on("logout", () => {
                    this.account = undefined;
                })
        }
    },
    methods: {
        doubleclickPolicy(event) {
            var uniqPolicy = event.data.uniqPolicy;

            if (this.epicSnapshot?.additionalCoverage) {
                this.additionalCoverageFiltered = Enumerable.from(this.epicSnapshot.additionalCoverage).where(x => x.uniqPolicy === uniqPolicy).toArray();
            }

            if (this.epicSnapshot?.physicalCoverage) {
                this.physicalCoverageFiltered = Enumerable.from(this.epicSnapshot.physicalCoverage).where(x => x.uniqPolicy === uniqPolicy).toArray();
            }

            if (this.epicSnapshot?.additionalInsured) {
                this.additionalInsuredFiltered = Enumerable.from(this.epicSnapshot.additionalInsured).where(x => x.uniqPolicy === uniqPolicy).toArray();
            }

            if (this.epicSnapshot?.drivers) {
                this.driversFiltered = Enumerable.from(this.epicSnapshot.drivers).where(x => x.uniqPolicy === uniqPolicy).toArray();
            }

            if (this.epicSnapshot?.equipment) {
                this.equipmentFiltered = Enumerable.from(this.epicSnapshot.equipment).where(x => x.uniqPolicy === uniqPolicy).toArray();
            }

            this.policyHeader = 'Policy Overview - Filtered on Policy # ' + event.data.mainPolicyNumber;
            this.toast.add({ severity: 'info', summary: 'Policy Filtered', detail: 'Policy Filtered by Policy #  ' + event.data.mainPolicyNumber + '.', life: 5000 });
        },
        clearFilter() {
            this.additionalCoverageFiltered = this.epicSnapshot?.additionalCoverage;
            this.physicalCoverageFiltered = this.epicSnapshot?.physicalCoverage;
            this.additionalInsuredFiltered = this.epicSnapshot?.additionalInsured;
            this.getDrivers();
            this.getEquipment();
            this.policyHeader = 'Policy Overview';
            this.toast.add({ severity: 'info', summary: 'Policy Filtered', detail: 'Filtered cleared.', life: 5000 });
        },
        async getData(lookupCode) {
            this.showSpinner();
            var epicRepository = new EpicRepository();

            try {
                this.epicSnapshot = await epicRepository.getEpicData(lookupCode, this.$store.account.username);
                this.additionalCoverageFiltered = this.epicSnapshot.additionalCoverage;
                this.physicalCoverageFiltered = this.epicSnapshot.physicalCoverage;
                this.additionalInsuredFiltered = this.epicSnapshot.additionalInsured;
                this.equipmentFiltered = this.epicSnapshot.equipment;
                this.getDrivers();
                this.getEquipment();
            }
            catch (error) {
                console.log(error);
                this.toast.add({ severity: 'error', summary: 'Error', detail: error?.response?.data?.title });
            }

            this.hideSpinner();
        },
        getDrivers() {
            var list = new Array();

            Enumerable.from(this.epicSnapshot.drivers).forEach(
                function (element) {
                    var driver = new Object();
                    driver.name = element.name;
                    driver.state = element.state;
                    driver.licenseNumber = element.licenseNumber;
                    driver.birthDate = element.birthDate;
                    driver.hireDate = element.hireDate;
                    driver.yearsExperience = element.yearsExperience;

                    list.push(driver);
                });

            list = Enumerable.from(list).distinct(x => JSON.stringify(x)).toArray();
            this.driversFiltered = list;
        },
        getEquipment() {
            var list = new Array();

            Enumerable.from(this.epicSnapshot.equipment).forEach(
                function (element) {
                    var equipment = new Object();
                    equipment.vehicleNumber = element.vehicleNumber;
                    equipment.vehicleYear = element.vehicleYear;
                    equipment.make = element.make.toUpperCase();
                    equipment.vin = element.vin.toUpperCase();
                    equipment.type = element.type;
                    equipment.vehicleType = element.vehicleType;
                    equipment.state = element.state;
                    equipment.costNew = element.costNew;
                    equipment.LossPayee = element.lp;
                    equipment.LossPayeeAddress = element.lP_Location;

                    list.push(equipment);
                });

            list = Enumerable.from(list).distinct(x => JSON.stringify(x)).toArray();
            this.equipmentFiltered = list;
        },
        hideSpinner() {
            var spinner = document.getElementById("spinner");
            spinner.style.display = "none";
        },
        showSpinner() {
            var spinner = document.getElementById("spinner");
            spinner.style.display = "block";
        },
        hideContainer() {
            var container = document.getElementById("container");
            container.classList.add("hidden");
        },
        showContainer() {
            var container = document.getElementById("container");
            container.classList.remove("hidden");
        },
        hideError() {
            var error = document.getElementById("error");
            error.style.display = "none";
        },
        showError() {
            var error = document.getElementById("error");
            error.style.display = "block";
        }
    }
}
</script>

<style scoped>
.spinner {
    margin-top: 75px;
    text-align: center;
}

.db-header {
    margin-left: 5px;
    margin-right: 5px;
}

.container {
    margin-top: 75px;
}

.hidden {
    visibility: hidden;
    display: none;
}

.container-new {
    display: flex;
    gap: 10px; /* Spacing between columns */
    justify-content: space-between; /* Distributes space evenly */
    flex-wrap: wrap; /* Ensures responsiveness */
}

.column-new {
    flex: 1; /* Makes all columns equal width */
    min-width: 200px; /* Prevents columns from shrinking too much */
    padding: 20px;
    background-color: #3498db;
    color: white;
    text-align: center;
    border-radius: 5px;
}
</style>
