<template>
  <div id="app">
    <AppHeader class="appHeader"/>
    <Dashboard/>
  </div>
</template>

<script>
import Dashboard from '@/components/Dashboard.vue';
import AppHeader from '@/components/AppHeader.vue';
import store from '@/store/store.js';

export default {
  name: 'App',
  store,
  components: { Dashboard, AppHeader },
};
</script>

<style>
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype"),
    url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}
</style>